import Model from '@/dc-it/models/Model'
import Field from '@/dc-it/types/Field'

export default class Period extends Model {
    endpoint = 'periods';

    fields = [
      new Field('id', 'Código').disabled(),
      new Field('name', 'Nombre'),
      new Field('days', 'Cantidad de días', 'number'),
    ];

    clone = () => Period;
}
